const Paths = [
    { "path_id": "1", "path_key": "i_b", "lat": "34.39933", "lon": "132.71111", "title": "総合科学部" },
    { "path_id": "2", "path_key": "i_e", "lat": "34.39933", "lon": "132.71111", "title": "総合科学部" },
    { "path_id": "3", "path_key": "i_j", "lat": "34.39933", "lon": "132.71111", "title": "総合科学部" },
    { "path_id": "4", "path_key": "i_k", "lat": "34.39933", "lon": "132.71111", "title": "総合科学部" },
    { "path_id": "5", "path_key": "i_i", "lat": "34.39933", "lon": "132.71111", "title": "総合科学部" },
    { "path_id": "6", "path_key": "i_j", "lat": "34.39933", "lon": "132.71111", "title": "総合科学部" },
    { "path_id": "7", "path_key": "i_m", "lat": "34.39933", "lon": "132.71111", "title": "総合科学部" },
    { "path_id": "8", "path_key": "i_s", "lat": "34.39933", "lon": "132.71111", "title": "総合科学部" },
    { "path_id": "9", "path_key": "k_b", "lat": "34.402974", "lon": "132.71126", "title": "教育学部" },
    { "path_id": "10", "path_key": "k_e", "lat": "34.402974", "lon": "132.71126", "title": "教育学部" },
    { "path_id": "11", "path_key": "k_j", "lat": "34.402974", "lon": "132.71126", "title": "教育学部" },
    { "path_id": "12", "path_key": "k_k", "lat": "34.402974", "lon": "132.71126", "title": "教育学部" },
    { "path_id": "13", "path_key": "k_i", "lat": "34.402974", "lon": "132.71126", "title": "教育学部" },
    { "path_id": "14", "path_key": "k_j", "lat": "34.402974", "lon": "132.71126", "title": "教育学部" },
    { "path_id": "15", "path_key": "k_m", "lat": "34.402974", "lon": "132.71126", "title": "教育学部" },
    { "path_id": "16", "path_key": "k_s", "lat": "34.402974", "lon": "132.71126", "title": "教育学部" },
    { "path_id": "17", "path_key": "l_b", "lat": "34.403279", "lon": "132.71315", "title": "中央図書館前" },
    { "path_id": "18", "path_key": "l_e", "lat": "34.403279", "lon": "132.71315", "title": "中央図書館前" },
    { "path_id": "19", "path_key": "l_j", "lat": "34.403279", "lon": "132.71315", "title": "中央図書館前" },
    { "path_id": "20", "path_key": "l_k", "lat": "34.403279", "lon": "132.71315", "title": "中央図書館前" },
    { "path_id": "21", "path_key": "l_i", "lat": "34.403279", "lon": "132.71315", "title": "中央図書館前" },
    { "path_id": "22", "path_key": "l_j", "lat": "34.403279", "lon": "132.71315", "title": "中央図書館前" },
    { "path_id": "23", "path_key": "l_m", "lat": "34.403279", "lon": "132.71315", "title": "中央図書館前" },
    { "path_id": "24", "path_key": "l_s", "lat": "34.403279", "lon": "132.71315", "title": "中央図書館前" },
    { "path_id": "25", "path_key": "n_b", "lat": "34.403574", "lon": "132.71431", "title": "北第１福利会館前" },
    { "path_id": "26", "path_key": "n_e", "lat": "34.403574", "lon": "132.71431", "title": "北第１福利会館前" },
    { "path_id": "27", "path_key": "n_j", "lat": "34.403574", "lon": "132.71431", "title": "北第１福利会館前" },
    { "path_id": "28", "path_key": "n_k", "lat": "34.403574", "lon": "132.71431", "title": "北第１福利会館前" },
    { "path_id": "29", "path_key": "n_i", "lat": "34.403574", "lon": "132.71431", "title": "北第１福利会館前" },
    { "path_id": "30", "path_key": "n_j", "lat": "34.403574", "lon": "132.71431", "title": "北第１福利会館前" },
    { "path_id": "31", "path_key": "n_m", "lat": "34.403574", "lon": "132.71431", "title": "北第１福利会館前" },
    { "path_id": "32", "path_key": "n_s", "lat": "34.403574", "lon": "132.71431", "title": "北第１福利会館前" },
    { "path_id": "33", "path_key": "c_b", "lat": "34.404663", "lon": "132.71471", "title": "中央口交差点" },
    { "path_id": "34", "path_key": "c_e", "lat": "34.404663", "lon": "132.71471", "title": "中央口交差点" },
    { "path_id": "35", "path_key": "c_j", "lat": "34.404663", "lon": "132.71471", "title": "中央口交差点" },
    { "path_id": "36", "path_key": "c_k", "lat": "34.404663", "lon": "132.71471", "title": "中央口交差点" },
    { "path_id": "37", "path_key": "c_i", "lat": "34.404663", "lon": "132.71471", "title": "中央口交差点" },
    { "path_id": "38", "path_key": "c_j", "lat": "34.404663", "lon": "132.71471", "title": "中央口交差点" },
    { "path_id": "39", "path_key": "c_m", "lat": "34.404663", "lon": "132.71471", "title": "中央口交差点" },
    { "path_id": "40", "path_key": "c_s", "lat": "34.404663", "lon": "132.71471", "title": "中央口交差点" },
    { "path_id": "41", "path_key": "j_b", "lat": "34.401838", "lon": "132.71427", "title": "情報科学部" },
    { "path_id": "42", "path_key": "j_e", "lat": "34.401838", "lon": "132.71427", "title": "情報科学部" },
    { "path_id": "43", "path_key": "j_j", "lat": "34.401838", "lon": "132.71427", "title": "情報科学部" },
    { "path_id": "44", "path_key": "j_k", "lat": "34.401838", "lon": "132.71427", "title": "情報科学部" },
    { "path_id": "45", "path_key": "j_i", "lat": "34.401838", "lon": "132.71427", "title": "情報科学部" },
    { "path_id": "46", "path_key": "j_j", "lat": "34.401838", "lon": "132.71427", "title": "情報科学部" },
    { "path_id": "47", "path_key": "j_m", "lat": "34.401838", "lon": "132.71427", "title": "情報科学部" },
    { "path_id": "48", "path_key": "j_s", "lat": "34.401838", "lon": "132.71427", "title": "情報科学部" },
    { "path_id": "49", "path_key": "m_b", "lat": "34.399781", "lon": "132.71339", "title": "工学部" },
    { "path_id": "50", "path_key": "m_e", "lat": "34.399781", "lon": "132.71339", "title": "工学部" },
    { "path_id": "51", "path_key": "m_j", "lat": "34.399781", "lon": "132.71339", "title": "工学部" },
    { "path_id": "52", "path_key": "m_k", "lat": "34.399781", "lon": "132.71339", "title": "工学部" },
    { "path_id": "53", "path_key": "m_i", "lat": "34.399781", "lon": "132.71339", "title": "工学部" },
    { "path_id": "54", "path_key": "m_j", "lat": "34.399781", "lon": "132.71339", "title": "工学部" },
    { "path_id": "55", "path_key": "m_m", "lat": "34.399781", "lon": "132.71339", "title": "工学部" },
    { "path_id": "56", "path_key": "m_s", "lat": "34.399781", "lon": "132.71339", "title": "工学部" },
    { "path_id": "57", "path_key": "d_b", "lat": "34.39847", "lon": "132.71344", "title": "学生会館前" },
    { "path_id": "58", "path_key": "d_e", "lat": "34.39847", "lon": "132.71344", "title": "学生会館前" },
    { "path_id": "59", "path_key": "d_j", "lat": "34.39847", "lon": "132.71344", "title": "学生会館前" },
    { "path_id": "60", "path_key": "d_k", "lat": "34.39847", "lon": "132.71344", "title": "学生会館前" },
    { "path_id": "61", "path_key": "d_i", "lat": "34.39847", "lon": "132.71344", "title": "学生会館前" },
    { "path_id": "62", "path_key": "d_j", "lat": "34.39847", "lon": "132.71344", "title": "学生会館前" },
    { "path_id": "63", "path_key": "d_m", "lat": "34.39847", "lon": "132.71344", "title": "学生会館前" },
    { "path_id": "64", "path_key": "d_s", "lat": "34.39847", "lon": "132.71344", "title": "学生会館前" }
]
export default Paths;
